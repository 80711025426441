import { ContactUs } from "../components/ContactUs";
import { ImageCarousel } from "../components/ImageCarousel"
import React, { useState, useEffect } from 'react';
import { useParams, Link} from 'react-router-dom';
import { formatDate,formatNumber } from "../utils/formatter";
import { useAuth } from "../components/auth/Auth";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit} from "@fortawesome/free-solid-svg-icons";

function CarInfo({ keyLabel, value }) {
  return (
    <div className="flex flex-wrap">
      <div style={{ color: "#999999" }} className="basis-0 grow px-3 max-w-[50%] break-word">{keyLabel}</div>
      <div className="basis-0 grow px-3 font-bold max-w-[50%] break-word">{value}</div>
    </div>
  );
}


export function CarDetail() {
  const { id } = useParams();
  const auth = useAuth();
  const [images, setImages] = useState([]);
  const [carDetails, setCarDetails] = useState(null);



  const fetchCar = async ()=>{
    try {
      const response = await fetch(`/api/vehicles/${id}/detail`);
      const data = await response.json();
      console.log(data)
      setCarDetails(data);
      setImages(data.images)
    } catch (error) {
        console.error('Error fetching cars:', error);
    } 
  }

  useEffect(()=>{
    fetchCar()
  },[])
  
  return (
    <main>
      {carDetails !== null &&
        <div className="flex flex-col items-center justify-center h-full w-fit mx-auto py-12">
          <div className="flex flex-col md:flex-row items-center justify-between mb-12 w-full">
            <div className="flex">
              <h1 className="text-3xl font-bold">{carDetails.brand} {carDetails.model}</h1>
              {auth.isLoggedIn &&
                <Link to={`/admin/dashboard/viaturas/${id}/editar`}  className="ml-4 bg-yellow-500 text-white px-4 py-2 rounded mr-2">
                  <FontAwesomeIcon icon={faEdit} />
                </Link>
              }
            </div>
            <div className="text-4xl font-bold">{formatNumber(carDetails.price)}€</div>
          </div>
          <div className="flex flex-wrap">
            <div className="w-[100vw] md:w-[50vw] mb-12 md:mb-0">
              <ImageCarousel images={images}/>
              <div className="mt-12 mb-0 md:mb-12">
                <div className="text-2xl font-bold mb-4">Mais detalhes</div>
                <div className="whitespace-pre-wrap text-lg leading-8 mb-16">
                  {carDetails.text}
                </div>
                <div className="text-lg leading-8">
                  ✔ Revisão e Check-Up
                </div>
                <div className="text-lg leading-8">
                  ✔ Tratamos do Seguro para a sua viatura
                </div>
                <div className="text-lg leading-8">
                  ✔ Possibilidade de financiamento até 120 meses
                </div>
                <div className="text-lg leading-8">
                  ✔ Compramos o seu carro
                </div>
                <div className="text-lg leading-8">
                  Estamos abertos todos os dias, incluindo domingos e feriados (mediante marcação prévia).
                </div>
                <div className="text-lg leading-8">
                  Este anúncio foi publicado por rotina informática, todos os dados carecem de confirmação junto do vendedor
                </div>
              </div>
            </div>
            <div className="car-details-sidebar flex-grow max-w-md ml-0 md:ml-10">
              <ul className="w-full">
                <li><CarInfo keyLabel="Marca" value={carDetails.brand} /></li>
                <li><CarInfo keyLabel="Modelo" value={carDetails.model} /></li>
                <li><CarInfo keyLabel="Segmento" value={carDetails.classification} /></li>
                <li><CarInfo keyLabel="Quilómetros" value={carDetails.km && `${formatNumber(carDetails.km)}km`} /></li>
                <li><CarInfo keyLabel="Mês/Ano" value={carDetails.date && formatDate(carDetails.date)} /></li>
                <li><CarInfo keyLabel="Combustível" value={carDetails.fuel_type} /></li>
                <li><CarInfo keyLabel="Lotação" value={carDetails.seats} /></li>
                <li><CarInfo keyLabel="Cilindrada (cm3)" value={carDetails.engine_capacity && `${formatNumber(carDetails.engine_capacity)}`} /></li>
                <li><CarInfo keyLabel="Potência (Cv)" value={carDetails.power && `${formatNumber(carDetails.power)}`} /></li>
                <li><CarInfo keyLabel="Tipo de Caixa" value={carDetails.gearbox} /></li>

              </ul>
            </div>
          </div>
        </div>
      }
      <ContactUs/>
    </main>

  )
}