import React from 'react';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import CardOverflow from '@mui/joy/CardOverflow';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoad } from "@fortawesome/free-solid-svg-icons";

import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { formatDate, formatNumber } from "../utils/formatter";

export function CarCard ({ id, date, fuel_type, km, image_url, brand, model,price }) {
    return (
        <Link to={`/viaturas/${id}`} style={{ textDecoration: 'none' }}>
            <Card 
                className="break-word" 
                sx={{
                    "--Card-padding": "0px",
                    "--Card-radius": "30px",
                    "border": 0,
                    "overflow": "hidden"
                }}
            >
                <CardOverflow>
                    <img style={{ 
                            width: "100%",
                            aspectRatio:16/9,
                            objectFit: "cover",
                        }}
                        src={image_url}
                    />
                    <div style={{ width:"100%", position: "relative", color: "white", bottom:"3em"}}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent:'space-evenly', maxWidth: '100%' }}>
                            <Typography
                                startDecorator={<CalendarMonthIcon style={{ fontSize: '32px' }}/>}
                                textColor="white"
                                fontWeight="lg"
                            >
                                {formatDate(date)}
                            </Typography>
                            <Typography
                                startDecorator={<LocalGasStationIcon style={{ fontSize: '32px' }}/>}
                                textColor="white"
                                fontWeight="lg"
                            >
                                {fuel_type}
                            </Typography>
                            <Typography
                                startDecorator={<FontAwesomeIcon icon={faRoad} style={{ fontSize: '24px' }}/>}
                                textColor="white"
                                fontWeight="lg"
                            >
                                {formatNumber(km)}
                            </Typography>
                        </Box>
                    </div>
                </CardOverflow>

                <CardContent sx={{ justifyContent: 'flex-end', ml:5, mb:5}}>
                    <h1 className="text-5xl font-extrabold text-gray-800">{brand}</h1>
                    <h3 className="text-2xl text-gray-800">{model}</h3>
                    <p className="text-3xl font-bold mt-5 text-[#000080]">
                        {formatNumber(price)}€
                    </p>
                </CardContent>
            </Card>
        </Link>
    );
};
