import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

// import required modules
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';

export function ImageCarousel({images}){
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <>
            <Swiper
                style={{
                '--swiper-navigation-color': '#fff',
                '--swiper-pagination-color': '#fff',
                }}
                loop={true}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="swiperUp"
            >
                {images.map(image => (
                    <SwiperSlide key={image.id}>
                        <img style={{ width: "100%", aspectRatio:16/9,objectFit: "cover" }}
                            srcSet={`${image.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            src={`${image.url}?w=248&fit=crop&auto=format`}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="swiperDown"
            >
                {images.map(image => (
                    <SwiperSlide key={image.id}>
                        <img style={{ width: "100%", aspectRatio:16/9,objectFit: "cover" }}
                            srcSet={`${image.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            src={`${image.url}?w=248&fit=crop&auto=format`}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
}