import React from 'react';
import { Swiper, SwiperSlide } from './SwiperWrapper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';

const images = [
  "4k-bmw-in-rain-sgm6aqfszlh16ezn.jpg",
  "706305.jpg",
  "IMG_3616.jpeg",
  "IMG_3617.jpeg",
  "IMG_3621.jpeg",
  "IMG_3622.jpeg",
  "IMG_3623.jpeg",
  "IMG_3624.jpeg"
]

export const AboutUsBanner = () => {
  return (
    <div style={{ backgroundColor: 'rgb(0, 0, 0)' }}>
      <a id="basic-hero-banner-0"></a>
      <div className="relative isolate overflow-hidden pt-14">
        <Swiper
          className="absolute inset-0 -z-10 h-full w-full"
          effect="fade"
          loop={true}
          autoplay={true}
          speed={3000}
        >
          {images.map((image, index) => (
              <SwiperSlide key={index} className="h-full w-full">
                <img
                  src={image}
                  className="h-full w-full object-cover"
                />
              </SwiperSlide>
            ))}
        </Swiper>
        <div className="absolute z-0 top-0 left-0 h-full w-full bg-[#000000] lg:w-3/4 lg:bg-transparent from-[#000000] from-50% to-transparent lg:bg-gradient-to-r" style={{ opacity: 0.3 }}></div>
        <div className="lg:max-w-7xl relative mx-auto max-w-2xl z-10px-8 lg:px-16">
          <div className="lg:w-2/3 xl:w-1/2 lg:mr-auto py-32">
            <div className="flex flex-col sm:block items-center sm:items-start justify-center text-center sm:text-left">
              <h2 className="sm:pt-48 pt-32 text-gray-100 text-6xl font-black tracking-tight">Sobre Nós</h2>
              <div className="justify-start mt-10 flex items-center gap-x-6">
                <a
                  href="#contact-us"
                  className="rounded-md px-5 py-3.5 text-xl font-bold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                  style={{ backgroundColor: 'rgb(184, 44, 6)', color: 'rgb(255, 255, 255)' }}
                >
                  Contacte-nos
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
