import React, { useEffect, useState } from 'react';
import { CarCarousel } from "./CarCarousel";

export function FeaturedCars(){
    const [cars, setCars] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchCars = async () => {
            try {
                const response = await fetch('/api/vehicles/featured');
                const data = await response.json();
                setCars(data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching cars:', error);
            }
        };

        fetchCars();
    }, []);
    return (
        <div id="featuredCars" className="px-8 py-48 bg-gray-900 w-100">
            <h1 className="text-center text-gray-100 font-bold text-4xl font-bold tracking-tight sm:text-6xl mb-16">Viaturas em Destaque</h1>
            {!isLoading && <CarCarousel cars={cars}/>}

            <div class="mx-auto mt-36 w-fit">
                <a 
                    href="/viaturas"
                    class="rounded-md bg-white px-7 py-5 text-xl font-semibold shadow-xl focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                    style={{
                        backgroundColor: "rgb(242, 2, 2)", color: "rgb(255, 255, 255)"
                    }}
                >
                    Ver Listagem de Viaturas
                </a>
            </div>
        </div>
    );
}