import React, { useState } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');


export function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone_number: '',
    subject: '',
    message: ''
  });

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);


  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch('/api/contact_requests/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        console.log('Message sent successfully!');

        setSubmissionSuccess(true);
        setModalIsOpen(true);


        // Reset form data after successful submission
        setFormData({
          name: '',
          email: '',
          phone_number: '',
          subject: '',
          message: ''
        });
      } else {
        setSubmissionSuccess(false);
        setModalIsOpen(true);
        console.error('Failed to send message:', response.statusText);
      }
    } catch (error) {
      setSubmissionSuccess(false);
      setModalIsOpen(true);
      console.error('Error sending message:', error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <a id="contact-us"></a>
      <div className="bg-gray-100 relative isolate px-6 py-16 sm:py-24 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-gray-900 text-3xl font-bold tracking-tight sm:text-4xl">Entre em Contacto</h2>
          <p className="text-gray-700 mt-2 text-lg leading-8">Prencha os campos abaixo e contactaremos mais tarde </p>
        </div>
        <form onSubmit={handleSubmit} className="mx-auto mt-16 max-w-4xl sm:mt-20" abineguid="C2CDD61B5F584BC38754751E786C61F2">
          <div className="wrapper grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6">
              <div className="field">
                <label htmlFor="name" className="text-gray-900 block text-sm font-semibold leading-6">
                  Nome <span className="text-red-600">*</span>
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="name"
                    required
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="field">
                <label htmlFor="email" className="text-gray-900 block text-sm font-semibold leading-6">
                    Email <span className="text-red-600">*</span>
                </label>
                <div className="mt-2.5 relative">
                    <input
                    type="email"
                    required
                    name="email"
                    autoComplete="email"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                    value={formData.email}
                    onChange={handleChange}
                    />
                    <div
                    id="pwm-inline-icon-21319"
                    className="pwm-field-icon"
                    style={{
                        position: 'absolute',
                        width: '18px',
                        height: '18px',
                        top: '50%',
                        right: '10px',
                        transform: 'translateY(-50%)',
                    }}
                    >
                    </div>
                </div>
              </div>
              <div className="field">
                <label htmlFor="phone-number" className="text-gray-900 block text-sm font-semibold leading-6">
                  Número de telefone
                </label>
                <div className="mt-2.5">
                  <input
                    type="tel"
                    name="phone_number"
                    autoComplete="tel"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                    value={formData.phone_number}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-x-8 gap-y-6">
              <div className="field">
                <label htmlFor="subject" className="text-gray-900 block text-sm font-semibold leading-6">
                  Assunto <span className="text-red-600">*</span>
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    required
                    name="subject"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                    value={formData.subject}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="field grow flex flex-col">
                <label htmlFor="message" className="text-gray-900 block text-sm font-semibold leading-6">
                  Mensagem <span className="text-red-600">*</span>
                </label>
                <div className="mt-2.5 grow flex flex-col">
                  <textarea
                    name="message"
                    required
                    rows="4"
                    className="grow block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10">
            <input type="hidden" name="hidden" value="" />
            <button
              type="submit"
              className="block w-full rounded-md px-3.5 py-2.5 text-center text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              style={{ backgroundColor: 'rgb(247, 8, 8)', color: 'rgb(255, 255, 255)' }}
            >
              Clique para ir dos 0 aos 100
            </button>
          </div>
        </form>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Success Modal"
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          overlayClassName="modal-overlay"
        >
          <div className="bg-white p-5 rounded shadow-lg">
            {submissionSuccess ? (
              <>
              <h2 className="text-xl font-semibold text-green-500 mb-4">Sucesso!</h2>
                <p className="mt-4">
                  O seu pedido foi efetuado com sucesso. Entraremos em contacto em breve.
                </p>
              </>
            ) : (
              <>
              <h2 className="text-xl font-semibold text-red-600 mb-4">Erro</h2>
              <p className="mt-4">
                  Não conseguimos efetuar o seu pedido. Por favor, tente novamente mais tarde.
              </p>
              </>
            )}
            <div className="flex justify-center mt-4">
              <button 
                onClick={closeModal} 
                className={`px-4 py-2 ${submissionSuccess ? 'bg-green-500':'bg-red-600'} text-white rounded-md`}
              >
                Fechar
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
