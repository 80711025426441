import { HomeBanner } from '../components/HomeBanner';
import { FeaturedCars } from '../components/FeaturedCars'
import { Testimonials } from '../components/Testimonials';
import { HomeAboutUs } from '../components/HomeAboutUs';
import { WhereWeAre } from '../components/WhereWeAre';
import { ContactUs } from '../components/ContactUs';
import { NeedHelp } from '../components/NeedHelp';

export function Home() {
    return (
        <>
          <HomeBanner />
          <FeaturedCars />
          <Testimonials />
          <NeedHelp />
          <HomeAboutUs />
          <WhereWeAre />
          <ContactUs />
        </>
    );
}
