import { AboutUsBanner } from "../components/AboutUsBanner";
import { AboutUsDescription } from "../components/AboutUsDescription";
import { ContactUs } from "../components/ContactUs";
import { WhereWeAre } from "../components/WhereWeAre";
export function AboutUs() {
    return (
        <>
          <AboutUsBanner/>
          <AboutUsDescription/>
          <WhereWeAre />
          <ContactUs/>
        </>
    );
}