import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./Auth";

function AuthProvider(props) {
    const savedToken = localStorage.getItem("jwtToken");
    const startLoggedIn = savedToken !== null;

    const [user, setUser] = useState(null);
    const [token, setToken] = useState(savedToken);
    const [isLoggedIn, setIsLoggedIn] = useState(startLoggedIn);
    const [isSuperUser, setIsSuperUser] = useState(false);

    const navigate = useNavigate();
    const saveToken = (token) => {
        if (token === null) {
            localStorage.removeItem("jwtToken");
        }
        else {
            localStorage.setItem("jwtToken", token);
        }
        setToken(token);
    }

    useEffect(()=>{
        testToken()
    },[])

    const testToken = async () => {
        const res = await authFetch("/api/login/test-token",{
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            }
        },)

        if(res.ok){
            const json_res = await res.json();
            setIsSuperUser(json_res.is_superuser)
        }
    }

    const getUserInfo = async () => {
        const res = await authFetch("/api/login/test-token",{
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            }
        },)

        if(res.ok){
            return await res.json();
        } 
    }

    const onLogIn = (token) => {
        setIsLoggedIn(true);
        saveToken(token)
    }
    const onLogout = () => {
        setIsLoggedIn(false);
        setUser(null);
        saveToken(null);
    }
    const onRegister = () => {
        setIsLoggedIn(true);
    }
    async function authFetch(url, params) {
        if (!isLoggedIn || token == null) {
            return false;
        }
        if (params === null || params === undefined) {
            params = {};
        }
        if (!params.headers) {
            params.headers = new Headers();
        }
        else {
            params.headers = new Headers(params.headers);
        }
        params.headers.set("Authorization", `Bearer ${token}`);
        const res = await fetch(url, params);
        if (res.status == 403) {
            const json_res = await res.json();
            const data = { errorMsg: json_res.detail }
            onLogout();
            navigate("/admin/login", { state:data} );
            
        }
        return res;
    }

    const logIn = async (username, password) => {
        const data = new FormData();
        data.append("username", username);
        data.append("password", password);
        const res = await fetch("/api/login/access-token", {
            method: 'POST',
            mode: 'cors',
            body: data,
        });
        const json_res = await res.json();
        if (res.ok) {
            const token = json_res.access_token
            onLogIn(token);
            await testToken();
            return null;
        } else {
            saveToken(null);
            setIsLoggedIn(false);
            return json_res.detail;
        }
    }
    const register = async (username, password, fullName) => {
        const data = { "username": username, "password": password, "full_name":fullName };
        const res = await authFetch("/api/users/", {
            method: 'post',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        if (res.ok) {
            return null;
        } else {
            const json_res = await res.json()
            return json_res.detail;
        }

    }
    const value = {
        user: user,
        setUser: setUser,
        isLoggedIn: isLoggedIn,
        isSuperUser: isSuperUser,
        logIn: logIn,
        register: register,
        onLogout: onLogout,
        onRegister: onRegister,
        onLogIn: onLogIn,
        fetch: authFetch,
        getUserInfo: getUserInfo
    };

    return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
}

export default AuthProvider;