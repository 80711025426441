import SearchBar from "../components/SearchBar";
import {CarCard} from "../components/CarCard";
import {WhereWeAre} from "../components/WhereWeAre";
import {ContactUs} from "../components/ContactUs";
import Slider from '@mui/material/Slider';
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export function Cars(){
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(100000);
    const [priceRange, setPriceRange] = useState([minPrice, maxPrice]);

    const [brands, setBrands] = useState([]);
    const [brandId,setBrandId] = useState("");

    const [models, setModels] = useState([]);
    const [modelId,setModelId] = useState("");


    const [fullTextQuery,setFullTextQuery] = useState("");

    const [classifications, setClassifications] = useState([]);
    const [classificationId, setClassificationId] = useState("");
    
    const [fuelTypes, setFuelTypes] = useState([]);
    const [fuelTypeId, setFuelTypeId] = useState("");

    const [sortByOptions, setSortByOptions] = useState([
        {sort_by:"title",sort_order:"asc",name:"Título: A a Z"}, 
        {sort_by:"title",sort_order:"desc",name:"Título: Z a A"}, 
        {sort_by:"price",sort_order:"asc",name:"O mais baixo"}, 
        {sort_by:"price",sort_order:"desc",name:"O mais alto"}]
    );

    const [sortByIdx, setSortByIdx] = useState(0);

    const [results, setResults] = useState([]);

    const fetchBrands = async ()=>{
        try {
            const response = await fetch('/api/brands/');
            const data = await response.json();
            setBrands(data);
        } catch (error) {
            console.error('Error fetching cars:', error);
        } 
    }

    const fetchClassifications = async ()=>{
        try {
            const response = await fetch('/api/classifications/');
            const data = await response.json();
            setClassifications(data);
        } catch (error) {
            console.error('Error fetching cars:', error);
        } 
    }


    const fetchFuelTypes = async ()=>{
        try {
            const response = await fetch('/api/fuel_types/');
            const data = await response.json();
            setFuelTypes(data);
        } catch (error) {
            console.error('Error fetching cars:', error);
        } 
    }

    const fetchModelsOfBrand = async(brand_id)=>{
        if(!brand_id){
            setModels([])
            return;
        }
        try {
            const response = await fetch(`/api/models/${brand_id}`);
            const data = await response.json();
            setModels(data);
        } catch (error) {
            console.error('Error fetching cars:', error);
        } 
    }

    const fetchPriceRangeAndSearch = async()=>{
        try {
            const response = await fetch('/api/vehicles/price-range');
            const data = await response.json();
            const {min_price,max_price} = data;
            setMinPrice(min_price);
            setMaxPrice(max_price)
            const newPriceRange = [min_price,max_price]
            setPriceRange(newPriceRange)
            searchCars(fullTextQuery,newPriceRange,brandId,modelId,classificationId,fuelTypeId,sortByIdx)
        } catch (error) {
            console.error('Error fetching cars:', error);
        } 
    }


    const urlEncodeArgs = (args)=>{
        return '?' + Object.entries(args)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');
    }

    const searchCars = async (fullTextQuery,priceRange,brandId,modelId,classificationId,fuelTypeId,sortByIdx)=>{
        try {
            const args = {};
            console.log(fullTextQuery)
            if (fullTextQuery){
                args["full_text_query"] = fullTextQuery
            } 
            args["min_price"] = priceRange[0]
            args["max_price"] = priceRange[1]

            if (brandId){
                args["brand_id"] = brandId
            } 
            if (modelId){
                args["model_id"] = modelId
            } 
            if (classificationId){
                args["classification_id"] = classificationId
            } 
            if (fuelTypeId){
                args["fuel_type_id"] = fuelTypeId
            } 
            const sortByOption = sortByOptions[sortByIdx]
            args["sort_by"] = sortByOption.sort_by
            args["sort_order"] = sortByOption.sort_order
            
            const response = await fetch(`/api/vehicles/search${urlEncodeArgs(args)}`);
            const data = await response.json();
            setResults(data);
        } catch (error) {
            console.error('Error fetching cars:', error);
        }
    }

    const fetchData = async () => {
        fetchBrands();
        fetchClassifications();
        fetchFuelTypes();
        fetchPriceRangeAndSearch();
    };

    useEffect(()=>{
        fetchData()
    },[])

    const onPriceRangeChange = (event, newValue) => {
        setPriceRange(newValue);
    };

    const chooseBrandOption = async (event) => {
        const brandId = event.target.value;
        setBrandId(brandId)
        await fetchModelsOfBrand(brandId);
    };

    const chooseModelOption = (event) => {
        setModelId(event.target.value);
    };

    const chooseClassificationOption = (event) => {
        setClassificationId(event.target.value);
    };

    const chooseFuelOption = (event) => {
        setFuelTypeId(event.target.value);
    };

    const chooseSortByOption = (event) => {
        setSortByIdx(event.target.value);
    };

    return(
    <>
        <div className="" style={{ backgroundColor: 'rgb(0, 0, 0)' }}>
            <div className="relative isolate overflow-hidden pt-14">
                <video className="absolute inset-0 -z-10 h-full w-full object-cover" autoPlay muted playsInline loop preload="auto" onContextMenu={() => false}>
                    <source src="vr_video_vehicles.mp4"/>
                </video>
                <div className="absolute z-0 top-0 left-0 h-full w-full bg-[#000000] lg:w-3/4 lg:bg-transparent from-[#000000] from-50% to-transparent lg:bg-gradient-to-r" style={{ opacity: 0.3 }}></div>
                <div className="lg:max-w-7xl relative mx-auto max-w-2xl z-10 py-32 px-8 lg:px-16">
                    <div className="lg:w-2/3 xl:w-1/2 lg:mr-auto">
                        <div className="text-left">
                            <p className="sm:pt-48 pt-32 text-gray-100 text-6xl font-black">Viaturas</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="w-full container mx-auto px-4 py-8 md:px-6 md:py-12 grid md:grid-cols-[240px_1fr] gap-12">
            <div className="bg-gray-100 rounded-lg shadow-md p-6 space-y-6">
                <h1>
                    Pesquisa Avançada
                </h1>
                <div>
                    <label className="block font-medium mb-2" htmlFor="brand">
                        Marca
                    </label>
                    <Select
                            className="w-full bg-white"
                            id="brand"
                            value={brandId}
                            onChange={chooseBrandOption}
                        >
                        <MenuItem key="" value="">Apagar seleção</MenuItem>

                        {brands.map(brand => (
                            <MenuItem key={brand.id} value={brand.id}>{brand.name}</MenuItem>
                        ))}
                    </Select>
                </div>
                <div>
                    <label className="block font-medium mb-2" htmlFor="model">
                        Modelo
                    </label>
                    <Select
                            className="w-full bg-white"
                            id="model"
                            value={modelId}
                            onChange={chooseModelOption}
                        >
                        <MenuItem key="" value="">Apagar seleção</MenuItem>

                        {models.map(model => (
                            <MenuItem key={model.id} value={model.id}>{model.name}</MenuItem>
                        ))}
                    </Select>
                </div>
                <div>
                    <label className="block font-medium mb-2" htmlFor="segment">
                        Categoria
                    </label>
                    <Select
                            className="w-full bg-white"
                            id="segment"
                            value={classificationId}
                            onChange={chooseClassificationOption}
                        >
                        <MenuItem key="" value="">Apagar seleção</MenuItem>
                        {classifications.map(classification=> (
                            <MenuItem key={classification.id} value={classification.id}>{classification.name}</MenuItem>
                        ))}
                    </Select>
                </div>
                <div>
                    <label className="block font-medium mb-2" htmlFor="fuel">
                        Combustível
                    </label>
                    <Select
                            className="w-full bg-white"
                            id="fuel"
                            value={fuelTypeId}
                            onChange={chooseFuelOption}
                        >
                        <MenuItem key="" value="">Apagar seleção</MenuItem>
                        {fuelTypes.map(fuelType => (
                            <MenuItem key={fuelType.id} value={fuelType.id}>{fuelType.name}</MenuItem>
                        ))}
                    </Select>
                </div>
            </div>
            <div className="bg-gray-100 rounded-lg shadow-md p-6 space-y-6 w-auto inline-block">
                <SearchBar value={fullTextQuery} onChange={(value)=>setFullTextQuery(value)} onCancelResearch={()=>setFullTextQuery("")}/>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div>
                        <label className="block font-medium mb-2" htmlFor="amount">
                            Preço
                        </label>
                        <input type="text" className="bg-inherit" id="amount" value={`${priceRange[0]}€ - ${priceRange[1]}€`} readOnly></input>
                        <Slider
                                className="max-w-64"
                                getAriaLabel={() => 'Price range'}
                                min={minPrice}
                                max={maxPrice}
                                value={priceRange}
                                color="black"
                                onChange={onPriceRangeChange}
                                valueLabelDisplay="auto"
                            />
                        </div>
                    <div className="w-full">
                        <label className="block font-medium mb-2" htmlFor="sort-by">
                            Ordenação
                        </label>
                        <Select
                            className="w-full max-w-64 bg-white"
                            id="sort-by"
                            value={sortByIdx}
                            onChange={chooseSortByOption}
                        >
                            {sortByOptions.map((sortByOption,index) => (
                                <MenuItem key={index} value={index}>{sortByOption.name}</MenuItem>
                            ))}
                        </Select>
                    </div>

                    <div className="w-full">
                        <label className="block font-medium mb-2" htmlFor="search-button">
                            Pesquisa
                        </label>
                        <Button 
                            sx={{ 
                                bgcolor: 'black',
                                color: 'white',
                                '&:hover': {
                                    bgcolor: 'white',
                                    color: 'black',
                                }
                            }}
                            className="w-full h-12 max-w-64"
                            id="search-button"
                            variant="contained"
                            onClick={()=>searchCars(fullTextQuery,priceRange,brandId,modelId,classificationId,fuelTypeId,sortByIdx)}
                        >
                        Pesquisar
                        </Button>
                    </div>
                </div>
                <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-6 ">
                    {results.map(car => (
                        <div key={car.id}>
                            <CarCard {...car} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
        <WhereWeAre />
        <ContactUs />
    </>
    );
}
