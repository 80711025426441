import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from './Header';
import { Footer } from './Footer';
import AuthProvider from './auth/AuthProvider';
import { ScrollToTop } from './ScrollToTop';

const MainLayout = () => {
  return (
    <AuthProvider>
      <Header />
      <ScrollToTop/>
      <main>
        <Outlet />
      </main>
      <Footer />
    </AuthProvider>
  );
};

export default MainLayout;
