import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from "../components/auth/Auth";

export function  ProtectedComponent(){
    const auth = useAuth()
    if(!auth.isLoggedIn)
        return <Navigate to="/admin/login" replace/>
    return <Outlet />
};
