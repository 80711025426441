import { Swiper, SwiperSlide } from './SwiperWrapper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-coverflow';
import { CarCard } from './CarCard';

export function CarCarousel({ cars }) {
  return (
    <Swiper
      style={{
        "--swiper-pagination-color": "black",
        "--swiper-pagination-bullet-inactive-color": "#999999",
        "--swiper-pagination-bullet-inactive-opacity": "1",
        "--swiper-pagination-bullet-size": "16px",
        "--swiper-pagination-bullet-horizontal-gap": "6px",
        "--swiper-navigation-color": "#fff",
      }}
      effect="coverflow"
      slidesPerView={1}
      spaceBetween={10}
      centeredSlides={true}
      loop={true}
      breakpoints={{
        480: {
          slidesPerView: 2,
          spaceBetween: 50,
        },
        960: {
          slidesPerView: 3,
          spaceBetween: 100,
        },
      }}
      pagination={{
        clickable: true,
      }}
      coverflowEffect={{
        rotate: -11,
        depth: 100,
        slideShadows: false,
      }}
      navigation={true}
    >
      {cars.map((car, index) => (
        <SwiperSlide key={index}>
          <CarCard {...car} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
