import React from 'react';

export function NeedHelp(){
  return (
    <div site="[object Object]">
      <a id="basic-contact-well-0"></a>
      <div className="bg-gray-900 relative isolate overflow-hidden">
        <div className="px-6 sm:px-6 py-16 sm:py-24 lg:px-8">
          <div className="text-left mx-auto max-w-2xl">
            <h2 className="text-gray-100 text-3xl font-bold tracking-tight sm:text-4xl">A sua Satisfação é a nossa prioridade</h2>
            <p className="text-gray-300 mr-auto mt-6 max-w-xl text-lg leading-8">Entre em contato connosco e encontre o carro ideal ainda hoje</p>
            <div className="justify-start mt-10 flex items-center gap-x-6">
              <a href="tel:934465465" className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2" style={{ backgroundColor: 'rgb(242, 2, 2)', color: 'rgb(255, 255, 255)' }}>934 465 465</a>
              <span className="text-sm font-semibold leading-6" style={{ color: 'rgb(255, 255, 255)' }}>Ligue já <span aria-hidden="true">→</span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}