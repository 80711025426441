import React, { useState, useEffect } from 'react';
import { useAuth } from "../components/auth/Auth";
import { useParams, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faStar as faStarSolid, faPlus} from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { ReactSortable } from "react-sortablejs";

const requiredCarDetails = {
    "price": "preço",
    "km": "quilómetros",
    "date": "data",
    "model_id": "modelo",
    "fuel_type_id": "combustível"
}

const stringAttributes = [
    "text"
]
  
export function CarDetailEdit() {
    const { id } = useParams();
    const auth = useAuth();
    const navigate = useNavigate();

    const [carDetails, setCarDetails] = useState({
        price: "",
        km: "",
        date: "",
        power:"",
        engine_capacity:"",
        model_id: "",
        classification_id: "",
        gearbox_id:"",
        fuel_type_id: "",
        seats: "",
        text: ""
    });

    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedBrandId, setSelectedBrandId] = useState("");
    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);
    const [classifications, setClassifications] = useState([]);
    const [fuelTypes, setFuelTypes] = useState([]);
    const [gearboxes, setGearboxes] = useState([]);
    const [images, setImages] = useState([]);
    const [featured, setFeatured] = useState(false);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        fetchVehicle();
        fetchBrands();
        fetchClassifications();
        fetchFuelTypes();
        fetchGearboxes();
    }, [auth.isLoggedIn]);

    const fetchVehicle = async () => {
        try {
            const response = await auth.fetch(`/api/vehicles/${id}/edit-detail`);
            const data = await response.json();
            const { featured, brand_id, images, ...newCarDetails } = data;
            setFeatured(featured);
            setImages(images);
            setSelectedBrandId(brand_id);
            fetchModelsOfBrand(brand_id);
            setCarDetails(newCarDetails);
        } catch (error) {
            console.error('Error fetching car:', error);
        }
    };

    const fetchBrands = async () => {
        try {
            const response = await fetch('/api/brands/');
            const data = await response.json();
            setBrands(data);
        } catch (error) {
            console.error('Error fetching brands:', error);
        }
    };

    const fetchClassifications = async () => {
        try {
            const response = await fetch('/api/classifications/');
            const data = await response.json();
            setClassifications(data);
        } catch (error) {
            console.error('Error fetching classifications:', error);
        }
    };

    const fetchFuelTypes = async () => {
        try {
            const response = await fetch('/api/fuel_types/');
            const data = await response.json();
            setFuelTypes(data);
        } catch (error) {
            console.error('Error fetching fuel types:', error);
        }
    };

    const fetchGearboxes = async () => {
        try {
            const response = await fetch('/api/gearboxes/');
            const data = await response.json();
            setGearboxes(data);
        } catch (error) {
            console.error('Error fetching gearboxes:', error);
        }
    };

    const fetchModelsOfBrand = async (brand_id) => {
        if (!brand_id) {
            setModels([]);
            return;
        }
        try {
            const response = await fetch(`/api/models/${brand_id}`);
            const data = await response.json();
            setModels(data);
        } catch (error) {
            console.error('Error fetching models:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCarDetails((prevCarDetails) => ({
            ...prevCarDetails,
            [name]: value,
        }));
    };

    const handleBrandChange = (e) => {
        setSelectedBrandId(e.target.value);
        fetchModelsOfBrand(e.target.value);
        setCarDetails((prevCarDetails) => ({
            ...prevCarDetails,
            model_id: null,
        }));
    }

    const handleSaveChanges = async () => {
        for (const key in carDetails) {
            if (Object.keys(requiredCarDetails).includes(key) && (carDetails[key]==="" || carDetails[key]===null)) {
                openErrorModal(`O campo ${requiredCarDetails[key]} é obrigatório`)
                return;
            }
        }
        try {
            const updatedCarDetails = { ...carDetails };

            //nulls are assumed to be the same as not present, empty strings are an actual value.
            //Empty string is only valid for string fields, convert all others to nulls
            Object.keys(updatedCarDetails).forEach((key) => {
                if (!stringAttributes.includes(key) && updatedCarDetails[key] === "") {
                    updatedCarDetails[key] = null;
                }
            });
            const response = await auth.fetch(`/api/vehicles/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedCarDetails),
            });
            if (response.ok) {
                navigate(`/viaturas/${id}`);
            } else {
                const errorData = await response.json();
                openErrorModal(errorData.detail || 'Erro ao guardar as mudanças');
            }
        } catch (error) {
            console.error('Error saving changes:', error);
            openErrorModal('Erro ao guardar as mudanças');
        }
    };

    const handleSaveImageOrder = async () => {
        const imageIds = images.map(image=>image.id)
        try {
            const response = await auth.fetch(`/api/vehicles/${id}/images/order`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(imageIds),
            });
            if (response.ok) {
                navigate(`/viaturas/${id}`);
            } else {
                const errorData = await response.json();
                openErrorModal(errorData.detail || 'Erro ao guardar a ordem das imagens');
            }
        } catch (error) {
            openErrorModal('Erro ao guardar a ordem das imagens');
        }
    }

    const openErrorModal = (message) => {
        setErrorMessage(message);
        setIsErrorModalOpen(true);
    };

    const closeErrorModal = () => {
        setIsErrorModalOpen(false);
        setErrorMessage('');
    };

    const openImageModal = () => {
        setIsImageModalOpen(true);
    };

    const closeImageModal = () => {
        setIsImageModalOpen(false);
        setSelectedFile(null);
    };


    const handleUploadedImageChange = (e) => {
        setSelectedFile(e.target.files[0])
    }


    const handleImageUpload = async () => {
        try {
            const formData = new FormData();
            formData.append(
                'image',
                selectedFile,
                selectedFile.name
            );
    
            const response = await auth.fetch(`/api/vehicles/${id}/images`, {
                method: 'POST',
                body: formData
            });
    

            const data = await response.json();
            if (!response.ok) {
                const errorData = await response.json();
                openErrorModal(errorData.detail || 'Erro ao guardar a imagem');
            }
            const newImage = {
                id: data.id,
                url: data.url
            };
            setImages((prevImages) => [...prevImages, newImage]);
            closeImageModal()

        } catch (error) {
            openErrorModal('Erro ao guardar a imagem');
        }
    };

    const handleImageDelete = async (imageId) => {
        try {
            const response = await auth.fetch(`/api/vehicles/${id}/images/${imageId}`, {
                method: 'DELETE'
            });
            if (response.ok) {
                setImages(images.filter(image => image.id !== imageId));
            } else {
                const errorData = await response.json();
                openErrorModal(errorData.detail || 'Erro ao apagar a imagem');
            }
        } catch (error) {
            openErrorModal('Erro ao apagar a imagem');
        }

    };

    const toggleFeatured = async () => {
        if (featured){
            try {
                const response = await auth.fetch(`/api/vehicles/${id}/featured`, {
                    method: 'PUT'
                });
                if (response.ok) {
                    setFeatured(false);
                } else {
                    const errorData = await response.json();
                    openErrorModal(errorData.detail || 'Erro ao marcar o carro como não destacado');
                }
            } catch (error) {
                openErrorModal('Erro ao marcar o carro como não destacado');
            }
        }
        else{
            try {
                const response = await auth.fetch(`/api/vehicles/${id}/featured`, {
                    method: 'PUT'
                });
                if (response.ok) {
                    setFeatured(true);
                } else {
                    const errorData = await response.json();
                    openErrorModal(errorData.detail || 'Erro ao marcar o carro como destacado');
                }
            } catch (error) {
                openErrorModal('Erro ao marcar o carro como destacado');
            }
        }
    };

    const deleteCar = async () => {
        try {
            const response = await auth.fetch(`/api/vehicles/${id}`, {
                method: 'DELETE'
            });
            if (response.ok) {
                navigate("/admin/dashboard")
            } else {
                const errorData = await response.json();
                openErrorModal(errorData.detail || 'Erro ao apagar a viatura');
            }
        } catch (error) {
            openErrorModal('Erro ao apagar a viatura');
        }
    }

    return (
        <>

            <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <div className="flex items-center justify-between mb-6">
                    <h1 className="text-2xl font-bold">Editar informações da Viatura</h1>
                    <div className="flex items-center space-x-4">
                        <button
                            type="button"
                            className="border border-gray-200 rounded-md p-2"
                            onClick={deleteCar}
                        >
                            <FontAwesomeIcon className="w-4 h-4 mr-2 " icon={faTrash} />
                            Apagar carro
                        </button>

                        <button
                        type="button"
                        className="border border-gray-200 rounded-md p-2"
                        onClick={toggleFeatured}
                        >
                            <FontAwesomeIcon
                                className={`w-4 h-4 mr-2 ${featured ? 'text-yellow-500' : 'text-gray-400'}`}
                                icon={featured ? faStarSolid : faStarRegular}
                            />
                            {featured ? 'Remover destaque' : 'Destacar'}
                        </button>
                    </div>
                </div>
                <form className="grid grid-cols-2 gap-6">
                    <div className="col-span-1">
                        <label className="block font-medium mb-2" htmlFor="brandId">Marca</label>
                        <Select
                            className="w-full bg-white"
                            name="brandId"
                            id="brandId"
                            value={selectedBrandId}
                            onChange={handleBrandChange}
                            required
                        >
                            {brands.map(brand => (
                                <MenuItem key={brand.id} value={brand.id}>{brand.name}</MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className="col-span-1">
                        <label className="block font-medium mb-2" htmlFor="model_id">Modelo</label>
                        <Select
                            className="w-full bg-white"
                            name="model_id"
                            id="model_id"
                            value={carDetails.model_id}
                            onChange={handleInputChange}
                            required
                        >
                            {models.map(model => (
                                <MenuItem key={model.id} value={model.id}>{model.name}</MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className="col-span-1">
                        <label className="block font-medium mb-2" htmlFor="classification_id">Categoria</label>
                        <Select
                            className="w-full bg-white"
                            name="classification_id"
                            id="classification_id"
                            value={carDetails.classification_id}
                            onChange={handleInputChange}
                        >
                            <MenuItem key="" value={null}>Apagar seleção</MenuItem>
                            {classifications.map(classification => (
                                <MenuItem key={classification.id} value={classification.id}>{classification.name}</MenuItem>
                            ))}
                        </Select>

                    </div>
                    <div className="col-span-1">
                        <label className="block font-medium mb-2" htmlFor="fuel_type_id">Combustível</label>
                        <Select
                            className="w-full bg-white"
                            name="fuel_type_id"
                            id="fuel_type_id"
                            value={carDetails.fuel_type_id}
                            onChange={handleInputChange}
                        >
                            {fuelTypes.map(fuelType => (
                                <MenuItem key={fuelType.id} value={fuelType.id}>{fuelType.name}</MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className="col-span-1">
                        <label className="block font-medium mb-2" htmlFor="km">Quilómetros</label>
                        <input
                            type="number"
                            name="km"
                            id="km"
                            value={carDetails.km || ''}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded"
                            required
                        />
                    </div>
                    <div className="col-span-">
                        <label className="block font-medium mb-2" htmlFor="date">Data</label>
                        <input
                            type="date"
                            name="date"
                            id="date"
                            value={carDetails.date || ''}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded"
                            required
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="block font-medium mb-2" htmlFor="seats">Lotação</label>
                        <input
                            type="number"
                            name="seats"
                            id="seats"
                            value={carDetails.seats || ''}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded"
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="block font-medium mb-2" htmlFor="price">Preço</label>
                        <input
                            type="number"
                            name="price"
                            id="price"
                            value={carDetails.price || ''}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded"
                            required
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="block font-medium mb-2" htmlFor="engine_capacity">Cilindrada (cm3)</label>
                        <input
                            type="number"
                            name="engine_capacity"
                            id="engine_capacity"
                            value={carDetails.engine_capacity || ''}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded"
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="block font-medium mb-2" htmlFor="power">Potência (Cv)</label>
                        <input
                            type="number"
                            name="power"
                            id="power"
                            value={carDetails.power || ''}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded"
                        />
                    </div>
                    <div className="col-span-2">
                        <label className="block font-medium mb-2" htmlFor="gearbox_id">Tipo de Caixa</label>
                        <Select
                            className="w-full bg-white"
                            name="gearbox_id"
                            id="gearbox_id"
                            value={carDetails.gearbox_id}
                            onChange={handleInputChange}
                        >
                            <MenuItem key="" value={null}>Apagar seleção</MenuItem>
                            {gearboxes.map(gearbox => (
                                <MenuItem key={gearbox.id} value={gearbox.id}>{gearbox.name}</MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className="col-span-2">
                        <label className="block font-medium mb-2" htmlFor="text">Descrição</label>
                        <textarea
                            name="text"
                            id="text"
                            value={carDetails.text}
                            onChange={handleInputChange}
                            className="w-full h-64 p-2 border rounded"
                        />
                    </div>
                </form>
                <div className="mt-8 flex justify-end">
                    <button type="button" className="border border-gray-200 rounded-md p-2" onClick={handleSaveChanges}>
                        Guadar Alterações
                    </button>
                </div>
                <div className="mt-8">
                    <div className="flex items-center justify-between mb-4">
                        <h2 className="text-xl font-bold">Imagens</h2>
                        <div>
                            <label className="inline-flex items-center cursor-pointer" htmlFor="image-upload">
                                <button
                                onClick={openImageModal}
                                className="border border-gray-200 rounded-md p-2"
                                >
                                    <FontAwesomeIcon  icon={faPlus} className="mr-2"/>
                                    Adicionar imagem
                                </button>
                                <input className="hidden" id="image-upload" type="file" />
                            </label>
                        </div>
                    </div>
                    <ReactSortable
                        filter=".addImageButtonContainer"
                        dragClass="sortableDrag"
                        className="grid grid-cols-4 gap-4"
                        list={images}
                        setList={setImages}
                        animation="200"
                        easing="ease-out"
                    >
                        {images.map(image => (
                            <div key={image.id} className="relative group">
                                <img 
                                    src={image.url}
                                    alt={`Image ${image.id}`}
                                    className="rounded-md object-cover w-full"
                                    width={200}
                                    style={{
                                        aspectRatio: "16/9",
                                        objectFit: "cover"
                                    }}
                                />
                                <div className="absolute top-2 right-2 flex items-center space-x-2 opacity-0 group-hover:opacity-100 transition-opacity">
                                    <button
                                        onClick={() => handleImageDelete(image.id)}
                                        className="bg-red-500 text-white p-1 rounded-full"
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </ReactSortable>
                    <div className="mt-4 flex justify-end">
                        <button type="button"  className="border border-gray-200 rounded-md p-2" onClick={handleSaveImageOrder}>
                                Guardar ordem das imagens
                        </button>
                    </div>
                </div>
            </div>

            <Modal
                isOpen={isImageModalOpen}
                onRequestClose={closeImageModal}
                contentLabel="Add image modal"
                className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
            >
                <div className="bg-white p-8 rounded-lg shadow-lg w-96">
                    <h2 className="text-xl font-semibold mb-4">Adicionar Imagem</h2>
                    <input
                        type="file"
                        onChange={handleUploadedImageChange}
                        className="mb-4"
                    />
                    <div className="flex justify-end">
                        <button
                            onClick={handleImageUpload}
                            className="bg-blue-500 text-white px-4 py-2 rounded-md mr-2"
                        >
                            Enviar
                        </button>
                        <button
                            onClick={closeImageModal}
                            className="bg-red-600 text-white px-4 py-2 rounded-md"
                        >
                            Fechar
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={isErrorModalOpen}
                onRequestClose={closeErrorModal}
                contentLabel="Error Modal"
                className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
            >
                <div className="bg-white p-5 rounded shadow-lg">
                    <h2 className="text-xl font-semibold text-red-600 mb-4">Erro</h2>
                    <p>{errorMessage}</p>
                    <div className="flex justify-center mt-4">
                        <button onClick={closeErrorModal} className="px-4 py-2 bg-red-600 text-white rounded-md">
                            Fechar
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
