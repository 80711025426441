import React from 'react';

export function Testimonials() {
  return (
    <div site="[object Object]">
      <a id="basic-testimonials-0"></a>
      <div className="bg-white py-16 sm:py-24">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-xl text-center">
            <h2 className="text-lg font-semibold leading-8 tracking-tight" style={{ color: 'rgb(184, 44, 6)' }}>Testemunhos</h2>
            <p className="text-gray-900 mt-2 text-3xl font-bold tracking-tight sm:text-4xl">O que os nossos clientes dizem... </p>
          </div>
          <div className="mx-auto mt-8 max-w-2xl sm:mt-10 lg:mx-0 lg:max-w-none">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              <div className="pt-8 sm:inline-block sm:w-full" data-sgpt-block="lstsilduieop6a4lcad">
                <figure className="bg-gray-50 rounded-2xl p-8 text-sm leading-6">
                  <blockquote className="text-gray-900">
                    <p>Muito bom serviço, sempre preocupado com o cliente...Eu recomento totalmente !! </p>
                  </blockquote>
                  <figcaption className="mt-6 flex items-center gap-x-4">
                    <img className="h-10 w-10 rounded-full" src="https://res.cloudinary.com/dq0gbgsdy/image/upload/c_fill,g_auto,w_160,h_160/uploads/YLWi5WHHI.jpg" alt="Renzo Romero Arenas" />
                    <div>
                      <div className="text-gray-900 font-semibold">Renzo Romero Arenas</div>
                    </div>
                  </figcaption>
                </figure>
              </div>
              <div className="pt-8 sm:inline-block sm:w-full" data-sgpt-block="lstsildupmlyihyit5s">
                <figure className="bg-gray-50 rounded-2xl p-8 text-sm leading-6">
                  <blockquote className="text-gray-900">
                    <p>Comprei meu carrinho no Stand VR, e recomendo. Pessoal de confiança, com atendimento personalizado e amigo.</p>
                  </blockquote>
                  <figcaption className="mt-6 flex items-center gap-x-4">
                    <img className="h-10 w-10 rounded-full" src="https://res.cloudinary.com/dq0gbgsdy/image/upload/c_fill,g_auto,w_160,h_160/uploads/ElQ-U2oMl.jpg" alt="Lito Lutonda" />
                    <div>
                      <div className="text-gray-900 font-semibold">Lito Lutonda</div>
                    </div>
                  </figcaption>
                </figure>
              </div>
              <div className="pt-8 sm:inline-block sm:w-full" data-sgpt-block="lstsildunejuyur4ew">
                <figure className="bg-gray-50 rounded-2xl p-8 text-sm leading-6">
                  <blockquote className="text-gray-900">
                    <p>Muito satisfeito com a compra do meu automóvel nesse stand! Ótimo atendimento e um serviço de confiança. Recomendo!</p>
                  </blockquote>
                  <figcaption className="mt-6 flex items-center gap-x-4">
                    <img className="h-10 w-10 rounded-full" src="https://res.cloudinary.com/dq0gbgsdy/image/upload/c_fill,g_auto,w_160,h_160/uploads/EC3SiPRJx.jpg" alt="Aloísio Ribeiro" />
                    <div>
                      <div className="text-gray-900 font-semibold">Aloísio Ribeiro</div>
                    </div>
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}