import React, { useState } from 'react';
import { useAuth } from "../components/auth/Auth";
import { Link } from 'react-router-dom';

export function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const auth = useAuth();
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="bg-brand-secondary relative sticky top-0 z-50">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5 flex items-center gap-3">
            <img
              src="/logo.svg"
              className="w-auto h-[53px]"
              alt="VR Automóveis"
            />
            <span className="text-white font-semibold">VR Automóveis</span>
          </a>
        </div>
        <div className="flex order-last ml-12">
          <button
            type="button"
            className="text-white -m-2.5 inline-flex items-center justify-center rounded-md p-2.5"
            onClick={toggleMenu}
          >
            <span className="sr-only">Abrir menu</span>
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"></path>
            </svg>
          </button>
        </div>
        <div className={`lg:flex lg:gap-x-12 lg:items-center ${menuOpen ? 'flex' : 'hidden'}`}>
          <a href="/" className="text-white text-lg leading-6">Home</a>
          <a href="/viaturas" className="text-white text-lg leading-6">Viaturas</a>
          <a href="/sobre-nos" className="text-white text-lg leading-6">Sobre Nós</a>
          <a href="tel:+351934465465" target="_self" className="text-white text-lg leading-6">+351 934 465 465</a>
          {auth.isLoggedIn && (
            <>
              <a href="/admin/dashboard" className="text-white text-lg leading-6">Dashboard</a>

              <Link to="/admin/login" onClick={auth.onLogout} className="text-lg leading-6 bg-[#FFFFFA] text-black px-4 py-2 rounded-md hover:bg-opacity-70 hover:text-white focus:outline-none"
              >
                Encerrar sessão
              </Link>
            </>
          )}
        </div>
      </nav>

      {/* Popup Content */}
      {menuOpen && (
        <div className="fixed inset-0 z-50 bg-black opacity-30"></div>
      )}
      <div className={`fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 ${menuOpen ? 'block' : 'hidden'}`}>
        <div className="flex items-center justify-between">
          <a href="/" className="flex items-center gap-3">
            <img src="/logo.svg" className="w-auto h-[53px]" alt="" />
          </a>
          <button
            type="button"
            className="-m-2.5 rounded-md p-2.5 text-gray-700"
            onClick={toggleMenu}
          >
            <span className="sr-only">Close menu</span>
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
        <div className="mt-6 flow-root">
          <div className="-my-6 divide-y divide-gray-500/10">
            <div className="space-y-2 py-6">
              <a href="/" className="-mx-3 block rounded-lg px-3 py-2 text-lg leading-7 text-gray-900 hover:bg-gray-50">Home</a>
              <a href="/viaturas" className="-mx-3 block rounded-lg px-3 py-2 text-lg leading-7 text-gray-900 hover:bg-gray-50">Viaturas</a>
              <a href="/sobre-nos" className="-mx-3 block rounded-lg px-3 py-2 text-lg leading-7 text-gray-900 hover:bg-gray-50">Sobre nós</a>
              <a href="tel:+351934465465" className="-mx-3 block rounded-lg px-3 py-2 text-lg leading-7 text-gray-900 hover:bg-gray-50">+351 934 465 465</a>
              {auth.isLoggedIn && (
                <>
                  <a href="/admin/dashboard" className="-mx-3 block rounded-lg px-3 py-2 text-lg leading-7 text-gray-900 hover:bg-gray-50">Dashboard</a>
                  <Link to="/admin/login" onClick={auth.onLogout} className="-mx-3 block text-lg leading-6 bg-brand-secondary text-white px-3 py-2 rounded-md hover:bg-opacity-70 hover:text-white focus:outline-none"
                  >
                    Encerrar sessão
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
