import React, { useState, useEffect } from 'react';
import { useAuth } from "../components/auth/Auth";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faSave, faPlus} from "@fortawesome/free-solid-svg-icons";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Modal from 'react-modal';


const stringAttributes = ["text"]

const requiredCarDetails = {
  "price": "preço",
  "km": "quilómetros",
  "date": "data",
  "model_id": "modelo",
  "fuel_type_id": "combustível"
}

export function AdminDashboard() {
  const auth = useAuth();

  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [brands, setBrands] = useState([]);
  const [carClassifications, setCarClassifications] = useState([]);
  const [fuelTypes, setFuelTypes] = useState([]);
  const [gearboxes, setGearboxes] = useState([]);
  const [selectedBrandId, setSelectedBrandId] = useState("");
  const [models,setModels] = useState([]);
  const [clientMessages, setClientMessages] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [newItemName, setNewItemName] = useState('');
  const [editItem, setEditItem] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isAddVehicleModalOpen, setIsAddVehicleModalOpen] = useState(false);
  const [carDetails, setCarDetails] = useState({
    price: null,
    km: null,
    date: null,
    power:null,
    engine_capacity:null,
    model_id: null,
    classification_id: null,
    gearbox_id: null,
    fuel_type_id: null,
    seats: null,
    text: ""
  });




  const openErrorModal = (message) => {
    setErrorMessage(message);
    setIsErrorModalOpen(true);
  };
  
  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorMessage('');
  };

  const openAddVehicleModal = () => {
    setIsAddVehicleModalOpen(true);
  };

  const closeAddVehicleModal = () => {
    setIsAddVehicleModalOpen(false);
  };

  useEffect(() => {
    if (auth.isSuperUser) {
      fetchUsers();
    }
    fetchBrands();
    fetchCarClassifications();
    fetchFuelTypes();
    fetchGearboxes()
    fetchMessages();
  }, [auth.isLoggedIn]);


  const fetchMessages = async() =>{
    const response = await auth.fetch('/api/contact_requests/');
    const data = await response.json();
    const utc = new Date();
    const offset = -utc.getTimezoneOffset();
    const newClientMessages = data.map(message => {
      const requested_at = new Date(message.requested_at);
      return {
        ...message,
        requested_at: new Date(requested_at.getTime() + offset*60000)
      }
    });
    setClientMessages(newClientMessages);
  }
  
  const fetchUsers = async () => {
    const response = await auth.fetch('/api/users/');
    const data = await response.json();
    setUsers(data.data);
  };

  const fetchBrands = async () => {
    const response = await auth.fetch('/api/brands/');
    const data = await response.json();
    setBrands(data);
  };

  const fetchCarClassifications = async () => {
    const response = await auth.fetch('/api/classifications/');
    const data = await response.json();
    setCarClassifications(data);
  };

  const fetchFuelTypes = async () => {
    const response = await auth.fetch('/api/fuel_types/');
    const data = await response.json();
    setFuelTypes(data);
  };

  const fetchGearboxes = async () => {
    const response = await auth.fetch('/api/gearboxes/');
    const data = await response.json();
    setGearboxes(data);
  };


  const handleAddNew = (type) => {
    setModalType(type);
    setIsModalOpen(true);
  };

  const handleMarkAsContacted = async (id) => {
    const response = await auth.fetch(`/api/contact_requests/${id}/contacted`, { method: 'PUT' });
    if (response.ok) {
      setClientMessages((prevMessages) =>
        prevMessages.map((message) =>
          message.id === id ? { ...message, contacted: true } : message
        )
      );
    }
    else{
      const data = await response.json();
      openErrorModal(data.detail)
    }
  };

  const handleDeleteMessage = async  (id) => {
    const response = await auth.fetch(`/api/contact_requests/${id}`, { method: 'DELETE' });
    if (response.ok) {
      setClientMessages((prevMessages) =>
        prevMessages.filter((message) => message.id !== id)
      );
    }
    else{
      const data = await response.json();
      openErrorModal(data.detail)
    }
  }

  const handleDelete = async (type, id) => {
    let url = '';
    switch (type) {
      case 'user':
        url = `/api/users/${id}`;
        break;
      case 'brand':
        url = `/api/brands/${id}`;
        break;
      case 'classification':
        url = `/api/classifications/${id}`;
        break;
      case 'fuelType':
        url = `/api/fuel_types/${id}`;
        break;
      case 'gearbox':
        url = `/api/gearboxes/${id}`;
        break;
      default:
        return;
    }

    const response = await auth.fetch(url, { method: 'DELETE' });
    if (response.ok) {
      switch (type) {
        case 'user':
          setUsers(users.filter(user => user.id !== id));
          break;
        case 'brand':
          setBrands(brands.filter(brand => brand.id !== id));
          break;
        case 'classification':
          setCarClassifications(carClassifications.filter(classification => classification.id !== id));
          break;
        case 'fuelType':
          setFuelTypes(fuelTypes.filter(fuelType => fuelType.id !== id));
          break;
        case 'gearbox':
          setGearboxes(gearboxes.filter(gearbox => gearbox.id !== id));
          break;
        default:
          return;
      }
    } else {
        const data = await response.json();
        openErrorModal(data.detail)
    }
  };

  const handleEditChange = (type, id, name) => {
    setEditItem({ type, id, name });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCarDetails((carDetails) => ({
        ...carDetails,
        [name]: value,
    }));
  };

  const handleSaveEdit = async () => {
    const { type, id, name } = editItem;
    let url = '';
    let body = JSON.stringify({ name });
    
    switch (type) {
      case 'brand':
        url = `/api/brands/${id}`;
        break;
      case 'classification':
        url = `/api/classifications/${id}`;
        break;
      case 'fuelType':
        url = `/api/fuel_types/${id}`;
        break;
      case 'gearbox':
        url = `/api/gearboxes/${id}`;
        break;
      default:
        return;
    }

    const response = await auth.fetch(url, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body
    });

    if (response.ok) {
      switch (type) {
        case 'brand':
          setBrands(brands.map(brand => brand.id === id ? { ...brand, name } : brand));
          break;
        case 'classification':
          setCarClassifications(carClassifications.map(classification => classification.id === id ? { ...classification, name } : classification));
          break;
        case 'fuelType':
          setFuelTypes(fuelTypes.map(fuelType => fuelType.id === id ? { ...fuelType, name } : fuelType));
          break;
        case 'gearbox':
          setGearboxes(gearboxes.map(gearbox => gearbox.id === id ? { ...gearbox, name } : gearbox));
          break;
        default:
          return;
      }
      setEditItem({});
    } else {
        const data = await response.json();
        openErrorModal(data.detail)
    }
  };
  const fetchModelsOfBrand = async (brand_id) => {
    if (!brand_id) {
        setModels([]);
        return;
    }
    try {
        const response = await fetch(`/api/models/${brand_id}`);
        const data = await response.json();
        setModels(data);
    } catch (error) {
        console.error('Error fetching models:', error);
    }
};

  const handleBrandChange = (e) => {
    setSelectedBrandId(e.target.value);
    fetchModelsOfBrand(e.target.value);
    setCarDetails((carDetails) => ({
        ...carDetails,
        model_id: null,
    }));
}

  const handleSaveCar = async () => {
    closeAddVehicleModal()

    for (const key in carDetails) {
        if (Object.keys(requiredCarDetails).includes(key) && (carDetails[key]==="" || carDetails[key]===null)) {
            openErrorModal(`O campo ${requiredCarDetails[key]} é obrigatório`)
            return;
        }
    }
    const updatedCarDetails = { ...carDetails };

    //nulls are assumed to be the same as not present, empty strings are an actual value.
    //Empty string is only valid for string fields, convert all others to nulls

    Object.keys(updatedCarDetails).forEach((key) => {
        if (!stringAttributes.includes(key) && updatedCarDetails[key] === "") {
            updatedCarDetails[key] = null;
        }
    });

    try {
        const response = await auth.fetch(`/api/vehicles/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedCarDetails),
        });
        const data = await response.json();

        if (response.ok) {
            closeAddVehicleModal()
            navigate(`/viaturas/${data.id}`);
        } else {
            openErrorModal(data.detail || 'Erro ao criar o carro');
        }
    } catch (error) {
        console.error('Error saving changes:', error);
        openErrorModal('Erro ao criar o carro');
    }
  };


  const handleSaveNew = async () => {
    let url = '';
    let body = JSON.stringify({ name: newItemName });

    switch (modalType) {
      case 'brand':
        url = `/api/brands/`;
        break;
      case 'classification':
        url = `/api/classifications/`;
        break;
      case 'fuelType':
        url = `/api/fuel_types/`;
        break;
      case 'gearbox':
        url = `/api/gearboxes/`;
        break;
      default:
        return;
    }

    const response = await auth.fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body
    });

    if (response.ok) {
      const newItem = await response.json();
      switch (modalType) {
        case 'brand':
          setBrands([...brands, newItem]);
          break;
        case 'classification':
          setCarClassifications([...carClassifications, newItem]);
          break;
        case 'fuelType':
          setFuelTypes([...fuelTypes, newItem]);
          break;
        case 'gearbox':
          setGearboxes([...gearboxes, newItem]);
          break;
        default:
          return;
      }
      setIsModalOpen(false);
      setNewItemName('');
    } else {
        const data = await response.json();
        openErrorModal(data.detail)
    }
  };


  return (
    <>
        <div className="container mx-auto p-6">
        <h1 className="text-2xl font-bold mb-4 text-center">Painel de Admin</h1>

        {auth.isSuperUser && (
            <div className="mb-6">
            <h2 className="text-xl font-semibold">Utilizadores</h2>
            <ul className="mt-4">
                {users.map(user => !user.is_superuser && (
                <li key={user.id} className="flex justify-between items-center bg-[#dddddd] bg-opacity-50 my-3 p-6 rounded-lg">
                    <div>
                    <span className="block font-bold mb-2">{user.username}</span>
                    <span className="block text-sm text-gray-500">{user.full_name}</span>
                    </div>
                    <div>
                    <button onClick={() => handleDelete('user', user.id)} className="bg-red-500 text-white px-4 py-2 rounded">
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                    </div>
                </li>
                ))}
            </ul>
            <Link to={'/admin/registar'} className="bg-blue-500 text-white px-4 py-2 rounded">
                Adicionar admin
            </Link>
            </div>
        )}

        <div className="mb-6">
            <h2 className="text-xl font-semibold">Marcas</h2>
            <ul className="mt-4">
            {brands.map(brand => (
                <li key={brand.id} className="flex justify-between items-center bg-[#dddddd] bg-opacity-50 my-3 p-6 rounded-lg">
                {editItem.type === 'brand' && editItem.id === brand.id ? (
                    <>
                    <input
                        type="text"
                        value={editItem.name}
                        onChange={(e) => handleEditChange('brand', brand.id, e.target.value)}
                        className="mr-2 p-1 border rounded"
                    />
                    <button onClick={handleSaveEdit} className="bg-green-500 text-white px-4 py-2 rounded">
                        <FontAwesomeIcon icon={faSave} />
                    </button>
                    </>
                ) : (
                    <>
                    <span>{brand.name}</span>
                    <div>
                        <Link to={`/admin/dashboard/marca/${brand.id}/modelos`} className="bg-blue-500 text-white px-4 py-2 rounded mr-2">
                        Ver modelos
                        </Link>
                        <button onClick={() => handleEditChange('brand', brand.id, brand.name)} className="bg-yellow-500 text-white px-4 py-2 rounded mr-2">
                        <FontAwesomeIcon icon={faEdit} />
                        </button>
                        <button onClick={() => handleDelete('brand', brand.id)} className="bg-red-500 text-white px-4 py-2 rounded">
                        <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </div>
                    </>
                )}
                </li>
            ))}
            </ul>
            <button onClick={() => handleAddNew('brand')} className="bg-blue-500 text-white px-4 py-2 rounded">
            <FontAwesomeIcon icon={faPlus} /> Adicionar marca
            </button>
        </div>

        <div className="mb-6">
            <h2 className="text-xl font-semibold">Categorias</h2>
            <ul className="mt-4">
            {carClassifications.map(classification => (
                <li key={classification.id} className="flex justify-between items-center bg-[#dddddd] bg-opacity-50 my-3 p-6 rounded-lg">
                {editItem.type === 'classification' && editItem.id === classification.id ? (
                    <>
                    <input
                        type="text"
                        value={editItem.name}
                        onChange={(e) => handleEditChange('classification', classification.id, e.target.value)}
                        className="mr-2 p-1 border rounded"
                    />
                    <button onClick={handleSaveEdit} className="bg-green-500 text-white px-4 py-2 rounded">
                        <FontAwesomeIcon icon={faSave} />
                    </button>
                    </>
                ) : (
                    <>
                    <span>{classification.name}</span>
                    <div>
                        <button onClick={() => handleEditChange('classification', classification.id, classification.name)} className="bg-yellow-500 text-white px-4 py-2 rounded mr-2">
                        <FontAwesomeIcon icon={faEdit} />
                        </button>
                        <button onClick={() => handleDelete('classification', classification.id)} className="bg-red-500 text-white px-4 py-2 rounded">
                        <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </div>
                    </>
                )}
                </li>
            ))}
            </ul>
            <button onClick={() => handleAddNew('classification')} className="bg-blue-500 text-white px-4 py-2 rounded">
            <FontAwesomeIcon icon={faPlus} /> Adicionar Categoria
            </button>
        </div>

        <div className="mb-6">
            <h2 className="text-xl font-semibold">Tipos de combustível</h2>
            <ul className="mt-4">
            {fuelTypes.map(fuelType => (
                <li key={fuelType.id} className="flex justify-between items-center bg-[#dddddd] bg-opacity-50 my-3 p-6 rounded-lg">
                {editItem.type === 'fuelType' && editItem.id === fuelType.id ? (
                    <>
                    <input
                        type="text"
                        value={editItem.name}
                        onChange={(e) => handleEditChange('fuelType', fuelType.id, e.target.value)}
                        className="mr-2 p-1 border rounded"
                    />
                    <button onClick={handleSaveEdit} className="bg-green-500 text-white px-4 py-2 rounded">
                        <FontAwesomeIcon icon={faSave} />
                    </button>
                    </>
                ) : (
                    <>
                    <span>{fuelType.name}</span>
                    <div>
                        <button onClick={() => handleEditChange('fuelType', fuelType.id, fuelType.name)} className="bg-yellow-500 text-white px-4 py-2 rounded mr-2">
                        <FontAwesomeIcon icon={faEdit} />
                        </button>
                        <button onClick={() => handleDelete('fuelType', fuelType.id)} className="bg-red-500 text-white px-4 py-2 rounded">
                        <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </div>
                    </>
                )}
                </li>
            ))}
            </ul>
            <button onClick={() => handleAddNew('fuelType')} className="bg-blue-500 text-white px-4 py-2 rounded">
            <FontAwesomeIcon icon={faPlus} /> Adicionar Tipo de Combustível
            </button>
        </div>

        <div className="mb-6">
            <h2 className="text-xl font-semibold">Tipos de Caixa</h2>
            <ul className="mt-4">
            {gearboxes.map(gearbox => (
                <li key={gearbox.id} className="flex justify-between items-center bg-[#dddddd] bg-opacity-50 my-3 p-6 rounded-lg">
                {editItem.type === 'gearbox' && editItem.id === gearbox.id ? (
                    <>
                    <input
                        type="text"
                        value={editItem.name}
                        onChange={(e) => handleEditChange('gearbox', gearbox.id, e.target.value)}
                        className="mr-2 p-1 border rounded"
                    />
                    <button onClick={handleSaveEdit} className="bg-green-500 text-white px-4 py-2 rounded">
                        <FontAwesomeIcon icon={faSave} />
                    </button>
                    </>
                ) : (
                    <>
                    <span>{gearbox.name}</span>
                    <div>
                        <button onClick={() => handleEditChange('gearbox', gearbox.id, gearbox.name)} className="bg-yellow-500 text-white px-4 py-2 rounded mr-2">
                        <FontAwesomeIcon icon={faEdit} />
                        </button>
                        <button onClick={() => handleDelete('gearbox', gearbox.id)} className="bg-red-500 text-white px-4 py-2 rounded">
                        <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </div>
                    </>
                )}
                </li>
            ))}
            </ul>
            <button onClick={() => handleAddNew('gearbox')} className="bg-blue-500 text-white px-4 py-2 rounded">
            <FontAwesomeIcon icon={faPlus} /> Adicionar Tipo de Caixa
            </button>
        </div>

        <div className="mb-6 flex flex-col">
            <h2 className="text-xl font-semibold">Viaturas</h2>
            <Link to={'/viaturas'} className="my-4 inline-block text-blue-500 hover:text-blue-700 transition duration-300 ease-in-out">
                Procurar viaturas
            </Link>
            <button onClick={openAddVehicleModal} className="bg-blue-500 text-white px-4 py-2 rounded w-48 mt-4">
              <FontAwesomeIcon icon={faPlus} /> Adicionar Viatura
            </button>
        </div>
        <div className="mb-6">
          <h2 className="text-xl font-semibold">Mensagens de Clientes</h2>
          <ul className="mt-4">
            {clientMessages.map(message => (
              <li key={message.id} className="bg-white flex justify-between items-center shadow rounded-lg p-4 mb-4">
                <div>
                  <div className="font-semibold">{message.name}</div>
                  <div className="text-gray-600">{message.subject}</div>
                  <div className="text-gray-600">{message.email}</div>
                  <div className="text-gray-600">{message.phone_number}</div>
                  <div className="text-gray-800">{message.message}</div>
                  <div className="text-gray-400">{message.requested_at.toLocaleString()}</div>
                  <div className="text-gray-400">{message.contacted ? "Contactado" : "Não contactado"}</div>
                </div>
                <div className="mt-2 flex space-x-2">
                  {!message.contacted && (
                    <button
                      onClick={() => handleMarkAsContacted(message.id)}
                      className="px-3 py-1 bg-blue-500 text-white rounded"
                    >
                      Marcar como contactado
                    </button>
                  )}
                  <button
                    onClick={() => handleDeleteMessage(message.id)}
                    className="px-3 py-1 bg-red-500 text-white rounded"
                  >
                    <FontAwesomeIcon icon={faTrash} /> Apagar
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {isModalOpen && (
            <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded shadow-lg">
                <h3 className="text-lg font-semibold">Adicionar {modalType === 'brand' ? 'Marca' : modalType === 'classification' ? 'Categoria' : modalType === 'fuelType' ? 'Tipo de Combustível':'Tipo de Caixa'}</h3>
                <input
                type="text"
                value={newItemName}
                onChange={(e) => setNewItemName(e.target.value)}
                className="mt-4 p-2 border rounded w-full"
                placeholder={'Nome'}
                />
                <div className="mt-4 flex justify-around">
                <button onClick={() => setIsModalOpen(false)} className="mr-2 bg-gray-500 text-white px-4 py-2 rounded">Cancelar</button>
                <button onClick={handleSaveNew} className="bg-blue-500 text-white px-4 py-2 rounded">Adicionar</button>
                </div>
            </div>
            </div>
        )}
        </div>
        <Modal
        isOpen={isErrorModalOpen}
        onRequestClose={closeErrorModal}
        contentLabel="Error Modal"
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
        >
            <div className="bg-white p-5 rounded shadow-lg">
            <h2 className="text-xl font-semibold text-red-600 mb-4">Erro</h2>
            <p>{errorMessage}</p>
            <div className="flex justify-center mt-4"> {/* Centering the close button */}
                <button onClick={closeErrorModal} className="px-4 py-2 bg-red-600 text-white rounded-md">
                    Fechar
                </button>
            </div>
            </div>
        </Modal>
        <Modal
            isOpen={isAddVehicleModalOpen}
            onRequestClose={closeAddVehicleModal}
            contentLabel="Adicionar veículo"
            className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-100"
        >
          <div className="w-full max-w-md md:max-w-2xl lg:max-w-4xl max-h-[50vh] overflow-y-scroll border border-gray-300 rounded-lg p-4 md:p-8">
            <div className="flex flex-col items-center justify-center h-100" >
              <h1 className="my-8 text-2xl font-bold">Informações do veículo</h1>
              <form className="grid grid-cols-2 gap-6">
                      <div className="col-span-1">
                          <label className="block font-medium mb-2" htmlFor="brandId">Marca</label>
                          <Select
                              className="w-full bg-white"
                              name="brandId"
                              id="brandId"
                              value={selectedBrandId}
                              onChange={handleBrandChange}
                              required
                          >
                              {brands.map(brand => (
                                  <MenuItem key={brand.id} value={brand.id}>{brand.name}</MenuItem>
                              ))}
                          </Select>
                      </div>
                      <div className="col-span-1">
                          <label className="block font-medium mb-2" htmlFor="model_id">Modelo</label>
                          <Select
                              className="w-full bg-white"
                              name="model_id"
                              id="model_id"
                              value={carDetails.model_id}
                              onChange={handleInputChange}
                              required
                          >
                              {models.map(model => (
                                  <MenuItem key={model.id} value={model.id}>{model.name}</MenuItem>
                              ))}
                          </Select>
                      </div>
                      <div className="col-span-1">
                          <label className="block font-medium mb-2" htmlFor="classification_id">Categoria</label>
                          <Select
                              className="w-full bg-white"
                              name="classification_id"
                              id="classification_id"
                              value={carDetails.classification_id}
                              onChange={handleInputChange}
                          >
                              <MenuItem key="" value={null}>Apagar seleção</MenuItem>
                              {carClassifications.map(classification => (
                                  <MenuItem key={classification.id} value={classification.id}>{classification.name}</MenuItem>
                              ))}
                          </Select>

                      </div>
                      <div className="col-span-1">
                          <label className="block font-medium mb-2" htmlFor="fuel_type_id">Combustível</label>
                          <Select
                              className="w-full bg-white"
                              name="fuel_type_id"
                              id="fuel_type_id"
                              value={carDetails.fuel_type_id}
                              onChange={handleInputChange}
                              required
                          >
                              {fuelTypes.map(fuelType => (
                                  <MenuItem key={fuelType.id} value={fuelType.id}>{fuelType.name}</MenuItem>
                              ))}
                          </Select>
                      </div>
                      <div className="col-span-1">
                          <label className="block font-medium mb-2" htmlFor="km">Quilómetros</label>
                          <input
                              type="number"
                              name="km"
                              id="km"
                              value={carDetails.km || ''}
                              onChange={handleInputChange}
                              className="w-full p-2 border rounded"
                              required
                          />
                      </div>
                      <div className="col-span-">
                          <label className="block font-medium mb-2" htmlFor="date">Data</label>
                          <input
                              type="date"
                              name="date"
                              id="date"
                              value={carDetails.date || ''}
                              onChange={handleInputChange}
                              className="w-full p-2 border rounded"
                              required
                          />
                      </div>
                      <div className="col-span-1">
                          <label className="block font-medium mb-2" htmlFor="seats">Lotação</label>
                          <input
                              type="number"
                              name="seats"
                              id="seats"
                              value={carDetails.seats || ''}
                              onChange={handleInputChange}
                              className="w-full p-2 border rounded"
                          />
                      </div>
                      <div className="col-span-1">
                          <label className="block font-medium mb-2" htmlFor="price">Preço</label>
                          <input
                              type="number"
                              name="price"
                              id="price"
                              value={carDetails.price || ''}
                              onChange={handleInputChange}
                              className="w-full p-2 border rounded"
                              required
                          />
                      </div>
                      <div className="col-span-1">
                          <label className="block font-medium mb-2" htmlFor="engine_capacity">Cilindrada (cm3)</label>
                          <input
                              type="number"
                              name="engine_capacity"
                              id="engine_capacity"
                              value={carDetails.engine_capacity || ''}
                              onChange={handleInputChange}
                              className="w-full p-2 border rounded"
                          />
                      </div>
                      <div className="col-span-1">
                          <label className="block font-medium mb-2" htmlFor="power">Potência (Cv)</label>
                          <input
                              type="number"
                              name="power"
                              id="power"
                              value={carDetails.power || ''}
                              onChange={handleInputChange}
                              className="w-full p-2 border rounded"
                          />
                      </div>
                      <div className="col-span-2">
                          <label className="block font-medium mb-2" htmlFor="gearbox_id">Tipo de Caixa</label>
                          <Select
                              className="w-full bg-white"
                              name="gearbox_id"
                              id="gearbox_id"
                              value={carDetails.gearbox_id}
                              onChange={handleInputChange}
                          >
                              <MenuItem key="" value={null}>Apagar seleção</MenuItem>
                              {gearboxes.map(gearbox => (
                                  <MenuItem key={gearbox.id} value={gearbox.id}>{gearbox.name}</MenuItem>
                              ))}
                          </Select>
                      </div>
                      <div className="col-span-2">
                          <label className="block font-medium mb-2" htmlFor="text">Descrição</label>
                          <textarea
                              name="text"
                              id="text"
                              value={carDetails.text}
                              onChange={handleInputChange}
                              className="w-full h-64 p-2 border rounded"
                              required
                          />
                      </div>
                  </form>
                  <div className="my-8 flex justify-end">
                          <button
                              onClick={handleSaveCar}
                              className="bg-blue-500 text-white px-4 py-2 rounded-md mr-8"
                          >
                              Guardar
                          </button>
                          <button
                              onClick={closeAddVehicleModal}
                              className="bg-red-600 text-white px-4 py-2 rounded-md ml-8"
                          >
                              Fechar
                          </button>
                  </div>
                  </div>

                </div>
        </Modal>

    </>
  );
}
