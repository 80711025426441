import React from 'react';

export function HomeBanner() {
    return (
        <div className="" site="[object Object]" style={{ backgroundColor: 'rgb(0, 0, 0)' }}>
        <a id="basic-hero-banner-0"></a>
        <div className="relative isolate overflow-hidden pt-14">
          <img
            srcSet="https://res.cloudinary.com/dq0gbgsdy/image/upload/uploads/LVYJ_0WCu.jpg 576w, https://res.cloudinary.com/dq0gbgsdy/image/upload/uploads/LVYJ_0WCu.jpg 768w, https://res.cloudinary.com/dq0gbgsdy/image/upload/uploads/LVYJ_0WCu.jpg 992w, https://res.cloudinary.com/dq0gbgsdy/image/upload/uploads/LVYJ_0WCu.jpg 1200w, https://res.cloudinary.com/dq0gbgsdy/image/upload/uploads/LVYJ_0WCu.jpg"
            sizes="(max-width: 576px) 576px, (max-width: 768px) 768px, (max-width: 992px) 992px, (max-width: 1200px) 1200px, 1400px"
            src="https://res.cloudinary.com/dq0gbgsdy/image/upload/uploads/LVYJ_0WCu.jpg"
            className="absolute inset-0 -z-10 h-full w-full object-cover"
          />
          <div className="absolute z-0 top-0 left-0 h-full w-full bg-[#000000] lg:w-3/4 lg:bg-transparent from-[#000000] from-50% to-transparent lg:bg-gradient-to-r" style={{ opacity: 0.59 }}></div>
          <div className="sm:py-48 lg:py-56 lg:max-w-7xl relative mx-auto max-w-2xl z-10 py-32 px-8 lg:px-16">
            <div className="lg:w-2/3 xl:w-1/2 lg:mr-auto">
              <div className="sm:justify-start hidden sm:mb-8 sm:flex">
                <div className="text-gray-100 relative rounded-full px-3 py-1 text-sm leading-6 ring-1 ring-white/50">A sua satisfação é a nossa prioridade</div>
              </div>
              <div className="text-left">
                <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">Mais de 20 anos de experiência</h1>
                <p className="text-gray-100 mt-6 text-lg font-semibold leading-8">✔ Compra e Venda de Carros</p>
                <p className="text-gray-100 mt-6 text-lg font-semibold leading-8">✔ Soluções de Financiamento</p>
                <p className="text-gray-100 mt-6 text-lg font-semibold leading-8">✔ Aceitámos Retomas</p>
                <p className="text-gray-100 mt-6 text-lg font-semibold leading-8">✔ Importamos o seu sonho</p>
                <div className="justify-start mt-10 flex items-center gap-x-6">
                  <a href="#contact-us" className="rounded-md px-5 py-3.5 text-xl font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2" style={{ backgroundColor: 'rgb(223, 26, 12)', color: 'rgb(255, 255, 255)' }}>Entrar em Contacto</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    );
}