import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../components/auth/Auth";

export const LogIn = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const {state} = useLocation();
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [errorMsg, setErrorMsg] = useState(state && state.errorMsg?state.errorMsg:null);


    const onClickLogIn = async () => {
        console.log(auth)
        if (username == null || password == null) {
            setErrorMsg("O nome de utilizador e a palavra-passe são obrigatórios");
            return;
        }
        const errors = await auth.logIn(username, password);
        if (!errors) {
            navigate("/admin/dashboard");
        } else {
            setErrorMsg(errors);
        }
    }

    const LogInFeedBack = () => {
        return (
            errorMsg && <p className="text-red-500 text-center my-4">{errorMsg}</p>
        );

    }
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle login logic here
    console.log('Username:', username);
    console.log('Password:', password);
  };

  return (
    <div className="bg-gray-100 flex items-center justify-center h-screen">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Autenticação</h2>
        <LogInFeedBack/>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="username" className="block text-gray-700">Nome de Utilizador</label>
            <input
              type="text"
              id="username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="mb-6">
            <label htmlFor="password" className="block text-gray-700">Palavra-passe</label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="flex flex-col items-center justify-between">
            <button
              type="submit"
              className="bg-brand-secondary text-white px-4 py-2 rounded-md"
              onClick={onClickLogIn}
            >
              Iniciar sessão
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
