import React from 'react';

export const AboutUsDescription = () => {
  return (
    <div>
      <a id="basic-content-image-0"></a>
      <div className="bg-gray-100">
        <div className="flex flex-col gap-16 mx-auto px-6 py-16 sm:py-24 lg:flex-row lg:gap-28 lg:px-8 lg:w-full lg:max-w-7xl">
          <div className="lg:w-4/6 max-w-xl mx-auto lg:max-w-none flex flex-col justify-center">
            <h2 className="text-gray-900 text-3xl font-bold tracking-tight sm:text-4xl">
              VR Automóveis
            </h2>
            <div className="text-gray-700 text-base leading-7">
              <div data-sgpt-block="ltq223j0utaagmspdqs">
                <h3 className="text-gray-900 mt-16 text-2xl font-bold tracking-tight">
                  Mais que carros
                </h3>
                <p className="mt-8">
                  Na VR Automóveis, cada cliente é tratado como parte da família. Com um amplo
                  conhecimento do mercado automotivo e um compromisso inabalável com a sua
                  satisfação, estamos prontos para superar suas expectativas. Durante mais de duas
                  décadas, construímos uma reputação de confiança e excelência. Nossa presença tanto
                  no mercado nacional quanto internacional nos permite oferecer uma seleção
                  diversificada de veículos, adaptados às suas necessidades específicas. Seja você um
                  motorista experiente ou um novato na estrada, estamos aqui para ajudá-lo a
                  encontrar o veículo perfeito que se adapte ao seu estilo de vida e orçamento.
                </p>
              </div>
            </div>
          </div>
          <div className="relative lg:w-2/6 order-first lg:pb-8">
              <img
                src="about_us_family.jpg"
                alt="VR Automóveis"
              />
          </div>
        </div>
      </div>
    </div>
  );
};