import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../components/auth/Auth";

export const Register = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);

  const onClickRegister = async () => {
    if (username === "" || password === "" || fullName === "") {
      setErrorMsg("Todos os campos são obrigatórios");
      return;
    }
    const errors = await auth.register(username, password, fullName);
    if (!errors) {
      navigate("/admin/dashboard");
    } else {
      setErrorMsg(errors);
    }
  };

  const RegisterFeedBack = () => {
    return errorMsg && <p className="text-red-500 text-center mt-4">{errorMsg}</p>;
  };

  return (
    <div className="bg-gray-100 flex items-center justify-center h-screen">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Criar Conta</h2>
        <RegisterFeedBack />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onClickRegister();
          }}
        >
          <div className="mb-4">
            <label htmlFor="username" className="block text-gray-700">
              Nome de Utilizador
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="mb-6">
            <label htmlFor="password" className="block text-gray-700">
              Palavra-passe
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="fullName" className="block text-gray-700">
              Nome Completo
            </label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="flex flex-col items-center justify-between">
            <button
              type="submit"
              className="bg-brand-secondary text-white px-4 py-2 rounded-md"
            >
              Criar novo utiliador
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};