import React, { useState, useEffect } from 'react';
import { useAuth } from "../components/auth/Auth";
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faSave, faPlus } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-modal';

export function AdminDashboardModels() {
  const auth = useAuth();
  const { brandId } = useParams();
  const [brand, setBrand] = useState(null);
  const [models, setModels] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newModelName, setNewModelName] = useState('');
  const [editModel, setEditModel] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);


  const openErrorModal = (message) => {
    setErrorMessage(message);
    setIsErrorModalOpen(true);
  };
  
  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorMessage('');
  };


  useEffect(() => {
    fetchBrand();
    fetchModelsOfBrand(brandId);
  }, [auth.isLoggedIn, brandId]);

  const fetchBrand = async () => {
    const response = await auth.fetch(`/api/brand/${brandId}`);
    const data = await response.json();
    setBrand(data);
  };

  const fetchModelsOfBrand = async (brandId) => {
    const response = await auth.fetch(`/api/models/${brandId}`);
    const data = await response.json();
    setModels(data);
  };

  const handleAddNew = () => {
    setIsModalOpen(true);
  };

  const handleDelete = async (modelId) => {
    const url = `/api/models/${modelId}`;
    const response = await auth.fetch(url, { method: 'DELETE' });

    if (response.ok) {
       setModels(models.filter(model => model.id !== modelId));
    } else {
        const data = await response.json();
        openErrorModal(data.detail)
    }
  };

  const handleEditChange = (modelId, name) => {
    setEditModel({ id: modelId, name });
  };

  const handleSaveEdit = async () => {
    if (brandId===null){
        console.error(`Failed to update model with id ${id}`);
        return;
    }
    const { id, name } = editModel;
    const url = `/api/models/${id}`;
    const body = JSON.stringify({
        brand_id:brandId,
        name:name
    });

    const response = await auth.fetch(url, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body
    });

    if (response.ok) {
      setModels(models.map(model => model.id === id ? { ...model, name } : model));
      setEditModel({});
    } else {
      const data = await response.json();
      openErrorModal(data.detail)
    }
  };

  const handleSaveNew = async () => {
    const url = `/api/models/`;
    const body = JSON.stringify({
        brand_id: brandId,
        name: newModelName
    });

    const response = await auth.fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body
    });

    if (response.ok) {
      const newModel = await response.json();
      setModels([...models, newModel]);
      setIsModalOpen(false);
      setNewModelName('');
    } else {
      const data = await response.json();
      openErrorModal(data.detail)
      console.error('Failed to create new model');
    }
  };

  return (
    <>
        <div className="container mx-auto p-6">
        <h1 className="text-2xl font-bold mb-4 text-center">Editar Modelos da Marca {brand?.name}</h1>

        <div className="mb-6">
            <h2 className="text-xl font-semibold">Modelos</h2>
            <ul className="mt-4">
            {models.map(model => (
                <li key={model.id} className="flex justify-between items-center bg-[#dddddd] bg-opacity-50 my-3 p-6 rounded-lg">
                {editModel.id === model.id ? (
                    <>
                    <input
                        type="text"
                        value={editModel.name}
                        onChange={(e) => handleEditChange(model.id, e.target.value)}
                        className="mr-2 p-1 border rounded"
                    />
                    <button onClick={handleSaveEdit} className="bg-green-500 text-white px-4 py-2 rounded">
                        <FontAwesomeIcon icon={faSave} />
                    </button>
                    </>
                ) : (
                    <>
                    <span>{model.name}</span>
                    <div>
                        <button onClick={() => handleEditChange(model.id, model.name)} className="bg-yellow-500 text-white px-4 py-2 rounded mr-2">
                        <FontAwesomeIcon icon={faEdit} />
                        </button>
                        <button onClick={() => handleDelete(model.id)} className="bg-red-500 text-white px-4 py-2 rounded">
                        <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </div>
                    </>
                )}
                </li>
            ))}
            </ul>
            <button onClick={handleAddNew} className="bg-blue-500 text-white px-4 py-2 rounded">
            <FontAwesomeIcon icon={faPlus} /> Adicionar Modelo
            </button>
        </div>

        {isModalOpen && (
            <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded shadow-lg">
                <h3 className="text-lg font-semibold">Adicionar Modelo</h3>
                <input
                type="text"
                value={newModelName}
                onChange={(e) => setNewModelName(e.target.value)}
                className="mt-4 p-2 border rounded w-full"
                placeholder="Nome do Modelo"
                />
                <div className="mt-4 flex justify-around">
                <button onClick={() => setIsModalOpen(false)} className="mr-2 bg-gray-500 text-white px-4 py-2 rounded">Cancelar</button>
                <button onClick={handleSaveNew} className="bg-blue-500 text-white px-4 py-2 rounded">Adicionar</button>
                </div>
            </div>
            </div>
        )}
        </div>
        <Modal
        isOpen={isErrorModalOpen}
        onRequestClose={closeErrorModal}
        contentLabel="Error Modal"
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
        >
            <div className="bg-white p-5 rounded shadow-lg">
            <h2 className="text-xl font-semibold text-red-600 mb-4">Erro</h2>
            <p>{errorMessage}</p>
            <div className="flex justify-center mt-4">
                <button onClick={closeErrorModal} className="px-4 py-2 bg-red-600 text-white rounded-md">
                    Fechar
                </button>
            </div>
            </div>
        </Modal>
    </>
  );
}
