import React from 'react';

export function WhereWeAre() {
  return (
    <div site="[object Object]">
      <a id="map-content-0"></a>
      <div className="bg-white py-16 sm:py-24">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-4">
              <div className="relative overflow-hidden rounded-3xl bg-gray-100 shadow-2xl lg:max-w-lg">
                <iframe 
                  width="100%"
                  height="448"
                  loading="lazy"
                  allowFullScreen=""
                  referrerPolicy="no-referrer-when-downgrade"
                  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBsh6yJba2R7lA-Varqc1qL9--6Xgmdi_w&amp;q=VR Automóveis"
                  style={{border: "0px"}}>
                </iframe>
              </div>
            </div>
            <div>
              <div className="text-gray-700 text-base leading-7 lg:max-w-lg">
                <p className="text-base font-semibold leading-7" style={{ color: 'rgb(184, 44, 6)' }}>A sua satisfação é a nossa prioridade</p>
                <h1 className="text-gray-900 mt-2 text-3xl font-bold tracking-tight sm:text-4xl">Onde estamos ?</h1>
                <div className="max-w-xl">
                  <div data-sgpt-block="lstsis37m1u7oo6jd5j">
                    <h2 className="text-gray-900 mt-16 text-2xl font-bold tracking-tight">Faça nos uma visita</h2>
                    <p className="mt-6">🏁 R. Central de Campo nº2205, 4440-037 Campo</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
