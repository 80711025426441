import React from 'react';

export function HomeAboutUs() {
  return (
    <div site="[object Object]">
      <a id="quote-floating-image-0"></a>
      <div className="bg-white py-16 sm:py-24">
        <div className="bg-white relative lg:z-10">
          <div className="from-gray-200 absolute top-0 right-0 w-full h-full z-0 bg-gradient-to-r to-transparent"></div>
          <div className="relative z-10 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-8 lg:px-8 py-24 sm:py-32 lg:py-0">
            <div className="relative lg:-my-8">
              <div className="mx-auto max-w-md px-6 sm:max-w-3xl lg:h-full lg:p-0">
                <div className="aspect-h-6 aspect-w-10 overflow-hidden rounded-xl shadow-xl sm:aspect-h-7 sm:aspect-w-16 lg:aspect-none lg:h-full">
                  <picture>
                    <source srcset="/placeholder.svg" media="(min-width: 1024px)" />
                    <img className="object-cover lg:h-full lg:w-full" src="/placeholder.svg" alt="Quem somos" />
                  </picture>
                </div>
              </div>
            </div>
            <div className="mt-12 lg:col-span-2 lg:m-0 lg:pl-8">
              <div className="mx-auto max-w-md px-6 sm:max-w-2xl lg:max-w-none lg:px-0 lg:py-20">
                <blockquote>
                  <div>
                    <div className="flex gap-5 items-center">
                      <h2 className="text-gray-900 text-3xl font-bold tracking-tight sm:text-4xl">Quem somos</h2>
                      <svg className="text-gray-900 h-12 w-12 opacity-25 order-first" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
                        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"></path>
                      </svg>
                    </div>
                    <p className="text-gray-900 mt-6 text-2xl font-medium">A VR Automóveis não é apenas uma empresa automotiva; somos uma extensão de seu lar, dedicados a proporcionar qualidade, confiança e transparência em todos os serviços que oferecemos.</p>
                    <p className="text-gray-900 text-2xl font-medium">Com mais de 20 anos de experiência tanto no mercado nacional quanto internacional.</p>
                    <p className="text-gray-900 text-2xl font-medium">Convidamos-lhe a fazer parte da nossa família e experimentar a diferença que fazemos em cada passeio.</p>
                    <p className="text-gray-900 text-2xl font-medium">Visite-nos hoje mesmo e deixe-nos ajudá-lo a encontrar o veículo perfeito para as suas necessidades porque na VR Automóveis a sua satisfação é a nossa prioridade!</p>
                  </div>
                  <footer className="mt-6">
                    <p className="text-gray-700 text-base font-medium" style={{ color: 'rgb(184, 44, 6)' }}>VR Automóveis</p>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}