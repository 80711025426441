import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Home } from './pages/Home';
import { Cars } from './pages/Cars';
import { CarDetail } from './pages/CarDetail';
import { AboutUs } from './pages/AboutUs';
import { LogIn } from './pages/LogIn';
import { Register } from './pages/Register';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { ScrollToTop } from './components/ScrollToTop';
import AuthProvider from "./components/auth/AuthProvider";
import { AdminDashboard } from './pages/AdminDashboard';
import { AdminDashboardModels } from './pages/AdminDashboardModels';
import { CarDetailEdit } from './pages/CarDetailEdit';
import { ProtectedComponent} from './components/ProtectedComponent';
import MainLayout from './components/MainLayout';

const router = createBrowserRouter([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '/', element: <Home /> },
        { path: '/sobre-nos', element: <AboutUs /> },
        { path: '/viaturas', element: <Cars /> },
        { path: '/viaturas/:id', element: <CarDetail /> },
        { path: '/admin/login', element: <LogIn /> },
        { path: '/admin/registar', element: <Register /> },
        {
          element: <ProtectedComponent />,
          children: [
            { path: '/admin/dashboard', element: <AdminDashboard /> },
            { path: '/admin/dashboard/marca/:brandId/modelos', element: <AdminDashboardModels /> },
            { path: '/admin/dashboard/viaturas/:id/editar', element: <CarDetailEdit /> },
          ],
        },
        { path: '*', element: <p>Página não encontrada</p> },
      ],
    },
  ]);

function App() {
    return (
      <RouterProvider router={router}>
      </RouterProvider>
    );
  }
  
export default App;