const formatDate = (date) => {
    const formattedDate = new Date(date);
    const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
    const year = formattedDate.getFullYear();

    return `${month}/${year}`;
};

const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '); // Add space every three digits
};

export {formatDate,formatNumber};